import { Application } from "@hotwired/stimulus";
import "@hotwired/turbo-rails";
import { registerControllers } from "../lib/stimulus_register";

// stimulus
const application = Application.start();
application.warnings = false;
application.debug = false;

registerControllers(
  application,
  import.meta.glob("../../../app/components/hairbook/**/*_controller.{js,ts}", {
    eager: true,
  }),
);
registerControllers(
  application,
  import.meta.glob("../../../app/components/ui/**/*_controller.{js,ts}", {
    eager: true,
  }),
);
registerControllers(
  application,
  import.meta.glob("../stimulus/*_controller.{js,ts}", {
    eager: true,
  }),
);

window.onload = function () {
  if (/iP(hone|ad)/.test(window.navigator.userAgent)) {
    document.body.addEventListener(
      "touchstart",
      function () {
        return true;
      },
      false,
    );
  }
};
